<template>
  <v-card flat>
    <v-card-subtitle> Credenciales </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="8" md="6">
                <SelectEmpleado
                  v-show="!datos.empleado"
                  :rules="!datos.empleado ? reglas.requerido: []"
                  :label="'Empleado'"
                  @GetResult="GetResultEmpleado"
                />
                <div v-if="datos.empleado">
                  <strong>Empleado</strong> <br />
                  <span>Documento: {{ datos.empleado.documento }} </span><br />
                  <span>Nombre: {{ datos.empleado.nombre }} {{ datos.empleado.apellido }}</span>
                </div>
                <v-text-field
                  :rules="reglas.usuario"
                  label="Usuario"
                  v-model="datos.nombreUsuario"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-6 mt-6"
                ></v-text-field>
                <v-checkbox v-show="datos.empleado" v-model="datos.indCambiarClave" :label="`Cambiar Contraseña`"></v-checkbox>
                <!-- new password -->
                <v-text-field
                  v-if="datos.indCambiarClave"
                  :rules="reglas.clave"
                  v-model="datos.clave"
                  :type="isNewPasswordVisible ? 'text' : 'password'"
                  :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  label="Contraseña"
                  outlined
                  dense
                  persistent-hint
                  placeholder="*********"
                  @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                ></v-text-field>

                <!-- confirm password -->
                <v-text-field
                  v-if="datos.indCambiarClave"
                  :rules="reglas.clave2"
                  v-model="datos.clave2"
                  :type="isCPasswordVisible ? 'text' : 'password'"
                  :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  label="Confirmar contraseña"
                  outlined
                  dense
                  class="mt-3"
                  placeholder="*********"
                  @click:append="isCPasswordVisible = !isCPasswordVisible"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="6" class="d-none d-sm-flex justify-center position-relative">
                <v-img
                  contain
                  max-width="130"
                  src="@/assets/images/3d-characters/pose-m-1.png"
                  class="security-character"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-tabs v-model="tab" background-color="deep-purple accent-4" centered icons-and-text>
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-1"> permisos por rol </v-tab>

              <v-tab href="#tab-2">permisos Individuales </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <v-card flat>
                  <v-card-text>
                    <div class="pa-3">
                      <v-card-title class="flex-nowrap">
                        <v-icon class="text--primary me-3">
                          {{ icons.mdiKeyOutline }}
                        </v-icon>
                        <span class="text-break">Permisos por rol globales</span>
                      </v-card-title>

                      <v-card-text>
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-treeview
                                v-model="datos.permisoGlobalRol"
                                :items="arboles.permisoGlobalRol"
                                selection-type="leaf"
                                item-children="children"
                                selectable
                                return-object
                                item-text="nombre"
                                item-key="onlyId"
                              ></v-treeview>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="pa-6" cols="6">
                              <template v-if="!datos.permisoGlobalRol.length">
                                <span class="error--text">* No tiene roles seleccionados.</span>
                              </template>
                              <template v-else>
                                <div style="max-height: 200px; overflow-y: auto">
                                  <div v-for="(node, index) in datos.permisoGlobalRol" :key="index">
                                    {{ node.descripcion }}
                                  </div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card-text>

                      <!-- action buttons -->
                    </div>
                  </v-card-text>

                  <v-card-text>
                    <div class="pa-3">
                      <v-card-title class="flex-nowrap">
                        <v-icon class="text--primary me-3">
                          {{ icons.mdiKeyOutline }}
                        </v-icon>
                        <span class="text-break">Permisos por rol globales</span>
                      </v-card-title>

                      <v-card-text>
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-treeview
                                v-model="datos.permisoSucursalRol"
                                :items="arboles.permisoSucursalRol"
                                selection-type="leaf"
                                item-children="children"
                                selectable
                                return-object
                                item-text="nombre"
                                item-key="onlyId"
                              ></v-treeview>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="pa-6" cols="6">
                              <template v-if="!datos.permisoSucursalRol.length">
                                <span class="error--text">* No tiene roles seleccionados.</span>
                              </template>
                              <template v-else>
                                <div style="max-height: 200px; overflow-y: auto">
                                  <div v-for="(node, index) in datos.permisoSucursalRol" :key="index">
                                    {{ node.descripcion }}
                                  </div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card-text>

                      <!-- action buttons -->
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card flat>
                  <v-card-text>
                    <div class="pa-3">
                      <v-card-title class="flex-nowrap">
                        <v-icon class="text--primary me-3">
                          {{ icons.mdiKeyOutline }}
                        </v-icon>
                        <span class="text-break">Permisos Individuales Globales </span>
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-treeview
                              v-model="datos.permisoGlobalIndividual"
                              :items="arboles.permisoGlobalIndividual"
                              selection-type="leaf"
                              selectable
                              return-object
                              item-text="nombre"
                              item-key="onlyId"
                            ></v-treeview>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col class="pa-6" cols="6">
                            <template v-if="!datos.permisoGlobalIndividual.length">
                              <span class="error--text">* No tiene permisos seleccionados.</span>
                            </template>
                            <template v-else>
                              <div style="max-height: 200px; overflow-y: auto">
                                <div v-for="(node, index) in datos.permisoGlobalIndividual" :key="index">
                                  {{ node.descripcion }}
                                </div>
                              </div>
                            </template>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </div>

                    <div class="pa-3">
                      <v-card-title class="flex-nowrap">
                        <v-icon class="text--primary me-3">
                          {{ icons.mdiKeyOutline }}
                        </v-icon>
                        <span class="text-break">Permisos Individuales Sucursal </span>
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-treeview
                              v-model="datos.permisoSucursalIndividual"
                              :items="arboles.permisoSucursalIndividual"
                              selection-type="leaf"
                              selectable
                              return-object
                              item-text="nombre"
                              item-key="onlyId"
                            ></v-treeview>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col class="pa-6" cols="6">
                            <template v-if="!datos.permisoSucursalIndividual.length">
                              <span class="error--text">* No tiene permisos seleccionados.</span>
                            </template>
                            <template v-else>
                              <div style="max-height: 200px; overflow-y: auto">
                                <div v-for="(node, index) in datos.permisoSucursalIndividual" :key="index">
                                  {{ node.descripcion }}
                                </div>
                              </div>
                            </template>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import SeguridadServices from '@/api/servicios/SeguridadServices'
import SelectEmpleado from '@/views/sistema/configuracion/empleado/componentes/Select.vue'
export default {
  components: { SelectEmpleado },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const selection = ref([])
    const items = ref([])
    const tab = ref('tab-1')
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)

    const roles = ref([])

    const arbolModulos = ref([])
    const datosInit = {
      id: -1 /*int*/,
      indCambiarClave: true,
      idEmpleado: null,
      empleado: null,
      nombreUsuario: '' /*int*/,
      clave: '' /*string*/,
      clave2: '' /*string*/,
      indActivo: null /*bool*/,
      permisoGlobalIndividual: [],
      permisoGlobalRol: [],
      permisoSucursalIndividual: [],
      permisoSucursalRol: [],
    }
    onBeforeMount(() => {
      CargarArbolModulo()
      CargarRoles()
    })
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      usuario: [
        v => !!v || 'Usuario es requerido',
        v => (v && v.length <= 20 && v.length >= 3) || 'Usuario debe ser valido',
      ],
      clave: [
        v => !!v || 'contraseñas es requerido',
        v => (v && v.length >= 4) || 'Contraseñas debe contener almenos 4 caracteres',
      ],
      clave2: [
        v => !!v || 'Confirmacion de contraseñas es requerido',
        v => (v && v == datos.value.clave) || 'Las contraseñas deben coincidir',
      ],
    }
    const datos = ref(JSON.parse(JSON.stringify(datosInit)))

    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify(datosInit))

      form.value.resetValidation()
    }
    const arboles = ref({
      permisoGlobalIndividual: [],
      permisoGlobalRol: [],
      permisoSucursalIndividual: [],
      permisoSucursalRol: [],
    })
    const CargarArbolModulo = () => {
      SeguridadServices.ArbolModuloGeneral({ idEmpresa: store.state.user.idEmpresa, idAplicacion: 1 })
        .then(response => {
          if (response.data.estatus == true) {
            arboles.value = response.data.datos
          } else {
            store.commit('setAlert', {
              message: `${response.data.mensaje}: `,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: `${err}: `,
            type: 'error',
          })
        })
      /*.finally(() => {

        })*/
    }

    const CargarRoles = () => {
      SeguridadServices.RolConsultar()
        .then(response => {
          if (response.data.estatus == true) {
            roles.value = response.data.datos
          } else {
            store.commit('setAlert', {
              message: `${response.data.mensaje}: `,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: `${err}: `,
            type: 'error',
          })
        })
      /*.finally(() => {

        })*/
    }
    const cargandos = ref(false)

    const CargarEditar = item => {
      datos.value = { ...item, idEmpleado: item.empleado.id,  clave: '', clave2: '', indCambiarClave: false }

      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()
     
      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }

    const GetResultEmpleado = item => {
      datos.value.idEmpleado = item.id
    }

    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          SeguridadServices.UsuarioActualizar(store.state.user.idUsuario, datos.value)
            .then(response => {
              console.log(response)
              if (response.data.estatus == true) {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          //alert(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      items,
      selection,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      tab,
      roles,
      arbolModulos,
      isCPasswordVisible,
      GetResultEmpleado,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      arboles,
    }
  },
}
</script>